import React, {useEffect, useRef} from 'react';
import '../../App.css';
import {Link} from 'react-router-dom';

// Function component using function keyword
const AccessibilityStatement = ({refToFooter}) => {

    useEffect(() => {
        const baseTitle = document.title.split('|')[0].trim(); // Obtén el título base
        document.title = `${baseTitle} | Declaración de Accesibilidad`; // Concatenamos el título específico
    }, []);

    const textRef = useRef(null);
    
    window.scrollTo({top: 0});

    const scrollToFooter = () => {
        if (refToFooter.current) {
          refToFooter.current.scrollIntoView();
        }
      };


    return (
        <>
        <div className='container-parent margin-bottom-xl'>
            <div className='container-child margin-top-paragraph'>
                <h1 className='accStatement'>DECLARACIÓN DE ACCESIBILIDAD</h1>
                <div className='div-text' ref={textRef}>
                    <p className="margin-top-m margin-bottom-m p-text">
                        <span className='bold'>DIAS</span> se ha comprometido a hacer accesible su sitio web, de conformidad con las <span className='bold'>Pautas de Accesibilidad para el Contenido Web</span> (en adelante, <span className='bold'>
                        <ruby>
                            <rb>WCAG</rb>
                            <rp>(</rp>
                            <rt>Wacag</rt>
                            <rp>)</rp>
                        </ruby></span>), para garantizar un acceso universal, de modo que pueda ser utilizado por el mayor número de personas, incluyendo personas con discapacidad. 
                    </p>
                    
                    <p className="margin-bottom-l p-text">
                        La presente declaración de accesibilidad se aplica al <span className='bold'>sitio web completo</span>.
                    </p>
                    
                    <h2 className='heading-text-left margin-bottom-m border-left-padding'>
                    Situación de Cumplimiento 
                    </h2>

                    <p className="margin-bottom-l p-text">
                    Este sitio web es <span className='bold'>totalmente conforme</span> con las <span className='bold'>WCAG versión 2.2</span> en su <span className='bold'>nivel AA</span>.
                    </p>
                    
                    <h2 className='heading-text-left margin-bottom-m border-left-padding'>
                    Tecnologías Utilizadas 
                    </h2>
                    <p className="margin-bottom-m p-text">
                    El siguiente es el listado de las tecnologías utilizadas en el sitio web:
                    </p>
                    
                    <ul className="margin-bottom-l p-text">
                        <li>HTML</li>
                        <li>CSS</li>
                        <li>JavaScript</li>
                        <li>WAI-ARIA</li>
                    </ul>

                    <h2 className='heading-text-left margin-bottom-m border-left-padding'>
                    Navegadores Soportados 
                    </h2>
                    <p className="margin-bottom-m p-text">
                    La siguiente tabla muestra los navegadores en los que se ha verificado el funcionamiento del sitio web:
                    </p>
                    
                    <table className="margin-bottom-m table-browser">
                        <caption className='sr-only'>Navegadores soportados por Sistema Operativo</caption>
                        <thead>
                            <tr>
                                <th scope='col'>Sistema Operativo</th>
                                <th scope='col'>Navegadores</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr lang='en-US'>
                                <th scope='row'>Android</th>
                                <td>Google Chrome <span lang='es-419'>y</span> Microsoft Edge</td>
                            </tr>
                            <tr lang='en-US'>
                                <th scope='row'>iOS</th>
                                <td>Google Chrome <span lang='es-419'>y Safari</span></td>
                            </tr>
                            <tr lang='en-US'>
                                <th scope='row'>macOS</th>
                                <td>Google Chrome <span lang='es-419'>y Safari</span></td>
                            </tr>
                            <tr lang='en-US'>
                                <th scope='row'>Windows</th>
                                <td>Google Chrome <span lang='es-419'>y</span> Microsoft Edge</td>
                            </tr>
                        </tbody>
                    </table>

                    <p className="margin-bottom-l p-text">
                    Sin perjuicio de lo anterior, pueden existir otros navegadores en los que el sitio web también funcione correctamente.
                    </p>


                    <h2 className='heading-text-left margin-bottom-m border-left-padding'>
                    Preparación de la presente Declaración
                    </h2>
                    <p className="margin-bottom-m p-text">
                    La presente declaración fue preparada el <span className='bold'>18 de julio de 2024</span>
                    </p>
                    <p className="margin-bottom-m p-text">
                    El método empleado para preparar la declaración ha sido una autoevaluación realizada por nuestro equipo, que consideró pruebas manuales y utilización de tecnologías de asistencia.
                    </p>
                    <p className="margin-bottom-l p-text">
                    La última revisión de la declaración fue realizada el <span className='bold'>01 de Octubre de 2024</span>
                    </p>
                    <h2 className='heading-text-left margin-bottom-m border-left-padding'>
                    Observaciones y Datos de Contacto
                    </h2>
                    <p className="margin-bottom-m p-text">
                    Cualquier usuario del sitio web puede contactarse para:
                    </p>
                    <ul className="margin-bottom-m p-text">
                        <li>Informar sobre cualquier <span className='bold'>incumplimiento</span> por parte de este sitio web</li>
                        <li>Comunicar <span className='bold'>dificultades de acceso</span> al contenido</li>
                        <li>Realizar <span className='bold'>consultas o sugerencias de mejora</span> relacionadas a la accesibilidad del sitio web</li>
                    </ul>
                    <p className="margin-bottom-m p-text">
                    a través del <Link to={{}} onClick={scrollToFooter} className='a-underline bold'>formulario de contacto</Link> o mediante el correo electrónico <Link to={"mailto:contacto@somosdias.com"}className='a-underline bold'>contacto@somosdias.com</Link>; el cual será respondido a la brevedad.
                    </p>
                </div>
            </div>
        </div>
        </>
    )
}

export default AccessibilityStatement