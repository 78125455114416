import React, {useImperativeHandle, useRef, forwardRef, useState} from 'react'
import { Button } from './ButtonIcon'
import './Footer.css'
import { Link } from 'react-router-dom'
import { useThemeContext } from '../context/ThemeContext';
import axios from 'axios';
import Alert from './Alert'
import wcag22aa from '../assets/images/wcag2.2AA-blue.png'
import vcss from '../assets/images/vcss-blue.png'

// Function component using arrow function syntax
const Footer = forwardRef((props, ref) => {
    const myRef = useRef();
    useImperativeHandle(ref,() => ({
        scrollIntoView: () => {
            myRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }));

    const {contextTheme} = useThemeContext();

   /* form, logic using one var (inputs) */
    const [inputs, setInputs] = useState({});
    //const [apiResult, setApiResult] = useState(null);
    //const [apiError, setApiError] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);

    const alert = document.getElementById('submitAlert');
    const submitButton = document.getElementById('submitButton');

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleSubmit = (event) => {
        submitButton.disabled = true;
        event.preventDefault();
        //axios.post('http://localhost:80/api/quotes/store', inputs)
        //axios.post('https://dias.thecgstore.com/api/quotes/store', inputs)
        axios.post('https://somosdias.com/api/quotes/store', inputs)
        .then(function(response) {
            console.log("handleSubmit, response: ", response);
            response.data.status === 1 ? alert.style.backgroundColor = "darkgreen" : alert.style.backgroundColor = "darkred";
            response.data.message ? setAlertMessage(response.data.message) : setAlertMessage("No hemos podido procesar tu consulta. Por favor, intenta más tarde.");
            alert.removeAttribute("role");
            response.data.status === 1 ? alert.setAttribute("role", "status") : alert.setAttribute("role", "alert");
        })
        //.catch(error => console.log("Ha ocurrido un error. code: ",error))
        .catch(function(error) {
            console.log("handleSubmit, error: ", error);
            //setApiError(error);
            alert.style.backgroundColor = "darkred"
            alert.removeAttribute("role");
            alert.setAttribute("role", "alert");
            //setAlertMessage(error.message);
            //setAlertMessage(apiError);
            setAlertMessage("No hemos podido procesar tu consulta. Por favor, intenta más tarde.");
        })
        //.finally(() => console.log('handleSubmit completed'));
        .finally(() => {
            console.log('handleSubmit completed');
            alert.classList.remove('alert-false');
            alert.classList.add('alert-true');
            submitButton.disabled = false;
            resetInputs();
        })
        console.log(inputs);
    }

    const resetInputs = () => {
        setInputs({
            username: "",
            email: "",
            service: "",
            message: ""
          });
    }

    return (
        <div id='footer' ref={myRef} className='footer-container'>
            <section className='footer-subscription'>
                <h1 className='container-child footer-subscription-heading'>
                    ¿Quieres que te contactemos?
                </h1>
                <p className='container-child footer-subscription-text semi-bold'>
                    Por favor, envíanos los siguientes datos para contactarte (todos los datos son requeridos):
                </p>
                <form onSubmit={handleSubmit} className='my-form'>
                    <div className="my-input-field">
                        <input id='username' name='username' className={`border-${contextTheme} outline-offset`} type='text' maxLength={50} value={inputs.username} onChange={handleChange} autoComplete='name' required />
                        <label htmlFor='username'>Nombre</label>
                    </div>
                    <div className="my-input-field">
                        <input id='email' name='email' className={`border-${contextTheme} outline-offset`} type='email' placeholder=' ' maxLength={50} value={inputs.email} onChange={handleChange} autoComplete='email' required />
                        <label htmlFor='email'>Correo</label>
                    </div>
                    <div className="my-input-field">
                        <select id="service" name="service" aria-label='Motivo' className={`border-${contextTheme} outline-offset`} value={inputs.service} defaultValue="" onChange={handleChange} required>
                            <option value="" id={`defaultoption-${contextTheme}`} disabled>Selecciona motivo:</option>
                            <option value="audit">Cotizar Auditoría</option>
                            <option value="consu">Cotizar Consultoría</option>
                            <option value="remed">Cotizar Remediación</option>
                            <option value="prob">Informar Problema</option>
                            <option value="otro">Otro</option>
                        </select>
                    </div>
                    <div className="my-input-field">
                        <textarea id='message' name="message" className={`border-${contextTheme} outline-offset`} maxLength={500} value={inputs.message} onChange={handleChange} required />
                        <label htmlFor='message'>Mensaje</label>
                    </div>
                    <Button
                    id='submitButton'
                    type='submit'
                    buttonStyle={`btn--${contextTheme}`}
                    d='M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12.2929 14.2929C11.9024 14.6834 11.9024 15.3166 12.2929 15.7071C12.6834 16.0976 13.3166 16.0976 13.7071 15.7071L16.6201 12.7941C16.6351 12.7791 16.6497 12.7637 16.6637 12.748C16.87 12.5648 17 12.2976 17 12C17 11.7024 16.87 11.4352 16.6637 11.252C16.6497 11.2363 16.6351 11.2209 16.6201 11.2059L13.7071 8.29289C13.3166 7.90237 12.6834 7.90237 12.2929 8.29289C11.9024 8.68342 11.9024 9.31658 12.2929 9.70711L13.5858 11H8C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H13.5858L12.2929 14.2929Z'
                    >Enviar
                    </Button>
                </form>
                <Alert message={alertMessage}></Alert>
            </section>
            <div className={`footer-links footer-links-${contextTheme}`}>
                <div className='footer-link-wrapper'>
                    <div className='footer-link-items'>
                        <h3>Más sobre DIAS</h3>

                        <Link to='https://somosdias.setmore.com/'>Agenda de Citas</Link>
                        <Link to='/accessibilityStatement'>Declaración de Accesibilidad</Link>  
                                                
                    </div>

                    <div className='footer-link-items'>
                        <h3>Redes sociales</h3>
                        
                        <Link lang='en-US'
                        className='social-icon-link facebook'
                        to='https://www.facebook.com/people/Somosdias/61565513760207/'
                        target='_blank'
                        aria-label='Facebook'>
                            <i className='fab fa-facebook' /> Facebook
                        </Link>

                        <Link lang='en-US'
                        className='social-icon-link instagram'
                        to='https://www.instagram.com/somos.dias/'
                        target='_blank'
                        aria-label='Instagram'>
                            <i className='fab fa-instagram' /> Instagram
                        </Link>

                        <Link lang='en-US'
                        className='social-icon-link linkedin'
                        to='https://www.linkedin.com/company/somosdias'
                        target='_blank'
                        aria-label='Linkedin'>
                            <i className='fab fa-linkedin' /> LinkedIn
                        </Link>

                        <Link lang='en-US'
                        className='social-icon-link tiktok'
                        to='https://www.tiktok.com/@somos.dias'
                        target='_blank'
                        aria-label='Tik Tok'>
                            <i className='fab fa-tiktok' /> Tik Tok
                        </Link>

                        <Link
                        className='social-icon-link twitter'
                        to='https://x.com/somosdias'
                        target='_blank'
                        aria-label='X (Twitter)'>
                            <i className='fab fa-x-twitter' /> X (Twitter)
                        </Link>
                    </div>
                    
                    <div className='footer-link-items'>
                        <h3>Enlaces de interés</h3>
                        <Link to='https://www.w3.org/WAI/standards-guidelines/es'>Estándares de Accesibilidad</Link>
                        <Link to='https://www.accessibilityassociation.org/s/'>IAAP</Link>
                        <Link to='https://www.bcn.cl/leychile/navegar?idNorma=1010903'>Ley de Discapacidad (20.422)</Link>
                    </div>
                </div>
                <div className='footer-link-wrapper gap-s'>
                    <a id='wcag-logo' href="https://www.w3.org/WAI/WCAG2AA-Conformance" title="Explicación de nivel de conformidad doble A">
                        <img className='w3c-logos'
                            src={wcag22aa}
                            alt="Nivel de conformidad doble A, Pautas de Accesibilidad al Contenido Web 2.2" />
                    </a>
                    <a id='css-logo' href="http://jigsaw.w3.org/css-validator/validator?uri=https://somosdias.com" title="Validación de hoja de estilos">
                        <img className='w3c-logos'
                            src={vcss}
                            alt="CSS Válido" />
                    </a>
                </div>

            </div>
        </div>    
    )

})

export default Footer