import React, { useRef, useEffect } from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './components/pages/Home';
import Services from './components/pages/Services';
import AboutUs from './components/pages/AboutUs';
import AccessibilityStatement from './components/pages/AccessibilityStatement';
import Footer from './components/Footer';
import Breadcrumbs from './components/Breadcrumbs';
import { Button } from './components/ButtonIcon';

function ScrollFocus() {
  const location = useLocation();  // Este hook detecta el cambio de la ruta

  useEffect(() => {
    // Cambia el foco al div "root" cuando la ruta cambie
    const rootElement = document.getElementById('root');
    if (rootElement) {
      rootElement.focus();
    }
  }, [location]);  // Se ejecuta cada vez que cambia la ruta

  return null;
}

function App() {
  const ref = useRef(null);

  // triggered when the user scrolls down
  window.onscroll = function () {
    scrollFunction();
  };

  // if user scrolls down 600px from the top of the document, show the button
  function scrollFunction() {
    if (document.body.scrollTop > 600 || document.documentElement.scrollTop > 600) {
      document.getElementById('btn_to_top').style.display = 'flex';
    } else {
      document.getElementById('btn_to_top').style.display = 'none';
    }
  }

  // triggered when the user click on "Subir" button
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <Router>
        <ScrollFocus /> {/* Componente para manejar el foco */}
        <header>
          <a href="#main-content">Saltar a contenido principal</a>
          <Navbar refToFooter={ref} />
          <Breadcrumbs />
        </header>
        <main id="main-content">
          <Routes>
            <Route exact path="/" element={<Home refToFooter={ref} />} />
            <Route exact path="/services" element={<Services />} />
            <Route exact path="/aboutUs" element={<AboutUs />} />
            <Route exact path="/accessibilityStatement" element={<AccessibilityStatement refToFooter={ref} />} />
          </Routes>
        </main>
        <Footer ref={ref} />
      </Router>
      <div>
        <Button id="btn_to_top" onClick={scrollToTop}>
          Subir
        </Button>
      </div>
    </>
  );
}

export default App;
